<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card>
          <v-data-table
            :headers="headers"
            :items="estados"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:[`item.esInicial`]="{ item }">
              <v-icon v-if="item.esInicial" small color="primary">
                {{ checkIcon }}
              </v-icon>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-col cols="8">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    color="primary"
                    @click="openModal()"
                    v-if="canCreate"
                    class="to-right"
                  >
                    Nuevo
                  </v-btn>
                </v-col>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModal(item.estadoActaId)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar estado</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteEstado(item.estadoActaId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar estado</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0 "
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="editEstadoDialog"
      v-model="editEstadoDialog"
      max-width="650px"
      @keydown.esc="closeModal()"
      persistent
    >
      <EditEstadosActasFiscalizacion
        :estadoActaId="estadoActaId"
        @closeAndReload="closeAndReload"
      />
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import EditEstadosActasFiscalizacion from "@/components/modules/cuotas/configuracion/EditEstadosActasFiscalizacion.vue";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "EstadosActasFiscalizacion",
  components: {
    PageHeader,
    DeleteDialog,
    EditEstadosActasFiscalizacion,
    GoBackBtn,
    Ayuda
  },

  data: () => ({
    editIcon: enums.icons.EDIT,
    searchIcon: enums.icons.SEARCH,
    search: "",
    deleteIcon: enums.icons.DELETE,
    checkIcon: enums.icons.CHECK_OUTLINE,
    showDeleteModal: false,
    idToDelete: null,
    editEstadoDialog: false,
    title: enums.titles.ESTADOS_ACTAS_FISCALIZACION,
    optionCode: enums.webSiteOptions.ESTADOS_ACTAS_FISCALIZACION,
    showHelp: false,
    showExpand: false,
    showIcon: true,
    routeToGo: "FiscalizacionDeAportes",
    titleDelete: "¿Eliminar estado?",
    estados: [],
    rules: rules,
    headers: [
      {
        text: "Estado",
        align: "start",
        value: "nombre",
        sortable: false
      },
      { text: "Descripción", value: "descripcion", sortable: false },
      {
        text: "Es inicial",
        value: "esInicial",
        align: "center",
        sortable: false
      },
      {
        text: "Tipo de registro AFIP ",
        value: "tipoRegistroAfip",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadEstados();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getEstadosActas: "configuracion/getEstadosActas",
      deleteEstadosActas: "configuracion/deleteEstadosActas",
      fetchTiposRegistrosAFIPEnum: "configuracion/fetchTiposRegistrosAFIPEnum",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_ESTADO_ACTA:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_ESTADO_ACTA:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_ESTADO_ACTA:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    async loadEstados() {
      this.estados = await this.getEstadosActas();
      const res = await this.fetchTiposRegistrosAFIPEnum();
      this.tiposRegistrosAfip = res;
      //recorre para cambiar el id por value para que se vea el nombre en la tabla
      this.estados.forEach(x => {
        this.tiposRegistrosAfip.forEach(y => {
          if (x.tipoRegistroAfip === y.id) {
            x.tipoRegistroAfip = y.value;
          } else if (x.tipoRegistroAfip === 0) {
            x.tipoRegistroAfip = null;
          }
        });
      });
    },
    deleteEstado(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteEstadosActas({
        estadoActaId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadEstados();
      }
    },
    openModal(estadoActaId) {
      this.editEstadoDialog = true;
      this.estadoActaId = estadoActaId;
    },
    closeModal() {
      this.editEstadoDialog = false;
    },
    closeAndReload() {
      this.editEstadoDialog = false;
      this.loadEstados();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
