<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="pl-1 primary--text">{{
          estadoActaId != null ? formEditTitle : formNewTitle
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            v-model="isFormValid"
            ref="crud-form"
            id="crud-form"
            @submit.prevent="saveEdit()"
          >
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model.trim="nombre"
                  label="Estado"
                  item-text="name"
                  item-value="id"
                  dense
                  outlined
                  :rules="
                    rules.required.concat([
                      rules.maxLength(nombre, 45),
                      rules.requiredTrim(nombre)
                    ])
                  "
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model="descripcion"
                  label="Descripción"
                  item-text="description"
                  item-value="id"
                  dense
                  outlined
                  :rules="[rules.maxLength(descripcion, 45)]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-select
                  v-model="tipoRegistroAfip"
                  :items="tiposRegistrosAfip"
                  item-text="value"
                  item-value="id"
                  label="Tipo Registro AFIP"
                  outlined
                  clearable
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="9" class="pb-0">
                <v-select
                  v-model="estadosActaSelected"
                  ref="estado-acta"
                  :items="estadosCombo"
                  item-text="nombre"
                  item-value="estadoActaId"
                  label="Estados destino"
                  outlined
                  clearable
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="3" class="pt-4 pb-0">
                <v-btn small @click="addEstadoSelected" color="primary"
                  >Agregar</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-simple-table dense v-if="estadosSelected.length > 0">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Estados destino:</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in estadosSelected" :key="item.id">
                        <td>{{ item.nombre }}</td>
                        <v-icon
                          small
                          class="ml-2 pt-2"
                          @click="deleteEstadosSelected(item)"
                        >
                          {{ deleteIcon }}
                        </v-icon>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  outlined
                  clearable
                  dense
                  multiple
                  v-model="usersSelected"
                  label="Usuarios con permiso"
                  item-text="value"
                  item-value="id"
                  autocomplete="on"
                  :items="usuarios"
                  return-object
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="usuariosToggle()">
                      <v-list-item-action>
                        <v-icon
                          :color="usersSelected.length > 0 ? 'primary' : ''"
                        >
                          {{ multiselectIcon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Todos </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.value }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ usersSelected.length - 1 }} otros)
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <div
                  v-if="usersSelected.length > 0"
                  id="simple-table-container"
                >
                  <v-simple-table dense v-if="usersSelected.length > 0">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Usuarios asignados:</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in usersSelected" :key="item.id">
                          <td>{{ item.value }}</td>
                          <v-icon
                            small
                            class="ml-2 pt-2"
                            @click="deleteUserSelected(item)"
                          >
                            {{ deleteIcon }}
                          </v-icon>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <v-switch
                  v-model="esInicial"
                  label="Es inicial"
                  item-text="esInicial"
                  item-value="id"
                  dense
                  outlined
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="crud-form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";

export default {
  name: "EditEstadosActasFiscalizacion",
  props: ["estadoActaId"],
  data: () => ({
    estadosSelected: [],
    usersSelected: [],
    estadosCombo: [],
    isFormValid: false,
    esInicial: false,
    editEstadoDialog: false,
    estadosActaSelected: null,
    estados: [],
    usuarios: [],
    tipoRegistroAfip: null,
    tiposRegistrosAfip: [],
    formNewTitle: enums.titles.NUEVO_ESTADO_ACTA,
    formEditTitle: enums.titles.EDITAR_ESTADO_ACTA,
    deleteIcon: enums.icons.DELETE,
    rules: rules,
    nombre: null,
    descripcion: null,
    tipoRegistroAfip: null
  }),
  created() {
    if (this.estadoActaId != null) {
      this.setEstado(this.estadoActaId);
    } else {
      this.newEstado();
    }
    this.loadEstados();
  },
  computed: {
    multiselectIcon() {
      if (this.selectAllUsuarios) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllUsuarios() {
      return (
        this.usersSelected && this.usersSelected.length === this.usuarios.length
      );
    }
  },
  methods: {
    ...mapActions({
      getUsuarios: "devengamientos/getUsuarios",
      getEstadosActasById: "configuracion/getEstadosActasById",
      postEstadosActas: "configuracion/postEstadosActas",
      fetchEstadosActaDestino: "configuracion/fetchEstadosActaDestino",
      getEstadosActas: "configuracion/getEstadosActas",
      fetchTiposRegistrosAFIPEnum: "configuracion/fetchTiposRegistrosAFIPEnum",
      setAlert: "user/setAlert"
    }),
    async setEstado() {
      const response = await this.getEstadosActasById(this.estadoActaId);
      this.nombre = response.nombre;
      this.descripcion = response.descripcion;
      this.tipoRegistroAfip = response.tipoRegistroAfip;
      this.estadosSelected = this.estados.filter(
        x => response.estadosDestinoIds.indexOf(x.estadoActaId) > -1
      );
      this.usersSelected = response.usuariosAsignados;
      this.esInicial = response.esInicial;
    },
    async newEstado() {
      this.formNewTitle;
      this.estadosCombo = [...this.estados];
    },
    async loadEstados() {
      const response = await this.getEstadosActas();
      this.estados = response;
      const res = await this.fetchTiposRegistrosAFIPEnum();
      this.tiposRegistrosAfip = res;
      const res1 = await this.getUsuarios();
      this.usuarios = res1;
      //recorre para cambiar el id por value para que se vea el nombre en la tabla
      this.estados.forEach(x => {
        this.tiposRegistrosAfip.forEach(y => {
          if (x.tipoRegistroAfip === y.id) {
            x.tipoRegistroAfip = y.value;
          } else if (x.tipoRegistroAfip === 0) {
            x.tipoRegistroAfip = null;
          }
        });
      });
      this.loadEstadosCombo();
    },
    async loadEstadosCombo() {
      this.estadosCombo = [...this.estados];
      this.estadosCombo = this.estados.filter(
        x => x.estadoActaId !== this.estadoActaId
      );
    },
    addEstadoSelected() {
      if (this.estadosSelected.length > 0) {
        let result = this.estadosSelected.find(
          x => x.estadoActaId === this.estadosActaSelected
        );
        if (result) {
          this.setAlert({
            type: "error",
            message: "Ya se encuentra en la lista"
          });
          return;
        }
      }
      const itemToAdd = this.estadosCombo.find(
        x => x.estadoActaId === this.estadosActaSelected
      );
      if (itemToAdd == null) {
        return;
      } else {
        this.estadosSelected.push(itemToAdd);
      }
    },
    async saveEdit() {
      const estadosDestinosIds = this.estadosSelected.map(x => x.estadoActaId);
      const usuariosIds = this.usersSelected.map(x => x.id);
      const data = {
        estadoActaId: this.estadoActaId,
        nombre: this.nombre,
        descripcion: this.descripcion,
        esInicial: this.esInicial,
        tipoRegistroAfip: this.tipoRegistroAfip,
        estadosDestinoIds: estadosDestinosIds,
        usuariosIds: usuariosIds
      };
      let res;
      if (this.nombre.length > 0) {
        let result = this.estados.find(x => x.nombre === this.nombre);
        if (result && data.estadoActaId != result.estadoActaId) {
          this.setAlert({
            type: "warning",
            message: `El nombre "${this.nombre}" ya se encuentra registrado`
          });
          return;
        } else {
          res = await this.postEstadosActas(data);
        }
      }
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
      }
      this.closeModal();
    },
    deleteEstadosSelected(item) {
      this.estadosSelected = this.estadosSelected.filter(
        x => x.estadoActaId !== item.estadoActaId
      );
    },
    deleteUserSelected(item) {
      this.usersSelected = this.usersSelected.filter(x => x.id !== item.id);
    },
    usuariosToggle() {
      this.$nextTick(() => {
        if (this.selectAllUsuarios) {
          this.usersSelected = [];
        } else {
          this.usersSelected = this.usuarios;
        }
      });
    },
    closeModal() {
      this.editEstadoDialog = false;
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style scoped>
#simple-table-container {
  overflow-x: hidden;
  width: 100%;
  overflow-y: scroll;
  max-height: 250px;
  min-height: 50px;
}
</style>
